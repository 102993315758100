// packages/app/src/components/signin/identityProviders.tsx

/* Backstage Azure SSO */
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';

export const providers = [
  {
    id: 'microsoft-auth-provider',
    title: 'Microsoft Entra ID',
    message: 'Sign in with your Microsoft Entra ID account',
    apiRef: microsoftAuthApiRef,
  },
];
/* Backstage Azure SSO */