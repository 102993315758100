// packages/app/src/theme/gluoTheme.ts
import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
  genPageTheme,
  shapes,
} from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#FFFFFF',
  };
});

export const gluoLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#111119',
        light: '#55f285',
        dark: '#1e3257',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#f23329',
        light: '#9129f2',
        dark: '#000',
        contrastText: '#def229',
      },
      navigation: {
        ...palettes.light.navigation,
        background: '#1e3257',
        color: '#b3ddf2',
        indicator: '#ffffff',
        selectedColor: '#55f285',
        navItem: {
          hoverBackground: '#2E8448',
        },
      },
      text: {
        primary: '#111119',
        secondary: '#1e3257',
        disabled: '#f3f4fe',
        hint: '#f3f4fe',
      },
      background: {
        default: '#ffffff',
        paper: '#D4D4D4',
      },
    },
  }),
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  pageTheme: {
    home: genPageTheme({ colors: ['#1e3257', '#000'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#1e3257', '#000'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#1e3257', '#000'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#1e3257', '#000'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#1e3257', '#000'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#1e3257', '#000'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#1e3257', '#000'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#1e3257', '#000'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#1e3257', '#000'], shape: shapes.wave }),
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
	  color: '#1e3257',
        },
      }
    },
    // Work in progress, does not have (much) effect
    MuiPaper: {
      styleOverrides: { 
	outlined: { 
		background: '#55f285',
	}, 
      }
    },
  },
});

export const gluoDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#55f285',
        light: '#D6D6D0',
        dark: '#9D6C6B',
        contrastText: '#111119',
      },
      secondary: {
        main: '#f23329',
        light: '#9129f2',
        dark: '#111119',
        contrastText: '#def229',
      },
      navigation: {
        ...palettes.dark.navigation,
        background: '#1e3257',
        color: '#b3ddf2',
        indicator: '#ffffff',
        selectedColor: '#ffffff',
        navItem: {
          hoverBackground: '#2E8448',
        },
      },
      text: {
        primary: '#D6D6D0',
        secondary: '#6BB381',
        disabled: '#f3f4fe',
        hint: '#9D6C6B',
      },
      background: {
        default: '#111119',
        paper: '#3A3A3A',
      },
    },
  }),
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
    h1: {
      fontSize: 54,
      fontWeight: 700,
      marginBottom: 10,
    },
    h2: {
      fontSize: 40,
      fontWeight: 700,
      marginBottom: 8,
    },
    h3: {
      fontSize: 32,
      fontWeight: 700,
      marginBottom: 6,
    },
    h4: {
      fontWeight: 700,
      fontSize: 28,
      marginBottom: 6,
    },
    h5: {
      fontWeight: 700,
      fontSize: 24,
      marginBottom: 4,
    },
    h6: {
      fontWeight: 700,
      fontSize: 20,
      marginBottom: 2,
    },
  },
  pageTheme: {
    home: genPageTheme({ colors: ['#1e3257', '#111119'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#1e3257', '#111109'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#1e3257', '#111119'], shape: shapes.round }),
    service: genPageTheme({
      colors: ['#1e3257', '#111119'],
      shape: shapes.wave,
    }),
    website: genPageTheme({
      colors: ['#1e3257', '#111119'],
      shape: shapes.wave,
    }),
    library: genPageTheme({
      colors: ['#1e3257', '#111119'],
      shape: shapes.wave,
    }),
    other: genPageTheme({ colors: ['#1e3257', '#111119'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#1e3257', '#111119'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#1e3257', '#111119'], shape: shapes.wave }),
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
	  color: '#D6D6D0',
        },
      }
    },
    MuiBox: {
      styleOverrides: { 
	root: { 
	  color: '#55f285',
	}, 
      }
    },
    // Work in progress, does not have (much) effect
    MuiPaper: {
      styleOverrides: { 
	outlined: { 
	  background: '#55f285',
	}, 
      }
    },
  },
});
