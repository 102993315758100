// packages/app/src/components/Root/LogoIcon.tsx
import React from 'react';
import MyCustomLogoIcon from './logo/Gluo_NV_icon.png';

const LogoIcon = () => {
  return (
    <img 
      src={MyCustomLogoIcon} 
      style={{ 
        height: '30px', 
        width: 'auto', 
      }} 
    />
  );
};

export default LogoIcon;