// packages/app/src/components/Root/LogoFull.tsx
import React from 'react';
import MyCustomLogoFull from './logo/Gluo_NV_logo_light.png';

const LogoFull = () => {
  return (
    <img 
      src={MyCustomLogoFull} 
      style={{ 
        height: '50px', 
        width: 'auto', 
      }} 
    />
  );
};

export default LogoFull;
