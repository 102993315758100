// packages/app/src/components/settings/SettingsPage.tsx
import React from 'react';
import {
  SettingsLayout,
  UserSettingsGeneral,
} from '@backstage/plugin-user-settings';

export const settingsPage = (
  <SettingsLayout>
    <SettingsLayout.Route path="general" title="General">
      <UserSettingsGeneral />
    </SettingsLayout.Route>
  </SettingsLayout>
);